<template>
  <GenericPage>
    <LandingPageHeader :blok="config.content.header[0]" />
    <HeaderSignUpCard v-if="isAnonymousUser" :blok="config.content.signUpCard[0]" />
    <BlogArticleGridView />
  </GenericPage>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBrandingStore } from '@/store/branding';
import type { BlogLandingPageConfigStory } from '@/types/storyblok';
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import GenericPage from '@/components/pages/GenericPage.vue';
import HeaderSignUpCard from '@/storyblok/components/common/sections/HeaderSignUpCard.vue';
import BlogArticleGridView from '@/components/blog/BlogArticleGridView.vue';
import { setSEOTags } from '@/utils/blog/seo';
import { useUserStore } from '@/store/user';
import { isUserLoggedIn } from '@/utils/user';
import { getRobotsTag } from '@/utils/meta';
import LandingPageHeader from '@/storyblok/components/common/sections/LandingPageHeader.vue';

const brandingStore = useBrandingStore();
const user = useUserStore();

const isAnonymousUser = computed(() => !isUserLoggedIn(user.info));

const config: BlogLandingPageConfigStory = await useAsyncStoryblokWithI18n(
  `branding/${brandingStore.data?.vendorId}/config/blog/landing-page`
);

await setSEOTags({
  title: 'Blog',
  robotsTag: getRobotsTag(brandingStore.data?.vendorId),
});
</script>
